import {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {store} from './redux/storeConfig/store'
import ability from './configs/acl/ability'
import {ToastContainer} from 'react-toastify'
import {AbilityContext} from './utility/context/Can'
import {SocketContext} from '@src/utility/context/Socket'
import {ThemeContext} from './utility/context/ThemeColors'
import {IntlProviderWrapper} from './utility/context/Internationalization'
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/components/ripple-button'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@styles/react/libs/toastify/toastify.scss'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import io from "socket.io-client"
import CacheBuster from 'react-cache-buster'
import {version} from '../package.json'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3"

const ENDPOINT = process.env.REACT_APP_CHAT_ENDPOINT
const CAPTCHA = process.env.REACT_APP_CAPTCHA_KEY
const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY
const SENTRY_DOMAIN = process.env.REACT_APP_SENTRY_DOMAIN
const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV
const socket = io(ENDPOINT, {transport: ['websocket']})
//const LazyApp = import('./App')
import App from './App'
import * as Sentry from "@sentry/react"

Sentry.init({
    dsn: SENTRY_ENV === 'production' ? SENTRY_KEY : '',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [SENTRY_DOMAIN],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner/>}>
            <AbilityContext.Provider value={ability}>
                <SocketContext.Provider value={socket}>
                    <ThemeContext>
                {/*        <IntlProviderWrapper>*/}
                {/*            <CacheBuster currentVersion={version}*/}
                {/*                         isEnabled={process.env.REACT_APP_ENV === 'production'}*/}
                {/*                         isVerboseMode={false}*/}
                {/*                         loadingComponent={<div className="mt-5 text-center"><h2>Checking for Updates...</h2></div>}>*/}
                                <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}>
                                    <App/>
                                </GoogleReCaptchaProvider>
                            {/*</CacheBuster>*/}
                            <ToastContainer newestOnTop/>
                        {/*</IntlProviderWrapper>*/}
                    </ThemeContext>
                </SocketContext.Provider>
            </AbilityContext.Provider>
        </Suspense>
    </Provider>,
    document.getElementById('root')
)
